export enum Color {
  White = '#FFFFFF',
  Black = '#000000',

  Neutral = '#CDCDCD',
  NeutralDark = '#202020',
  NeutralLight = '#F8F8F8',
  Grey = '#7c7c7c',
  GreyDark = '#6C6C6C',

  Primary = '#00AF00',
  PrimaryDark = '#c4363e',
  PrimaryLight = '#ff9b97',

  Secondary = '#00AF00',
  SecondaryDark = '#005E00',
  SecondaryLight = '#00AF00',

  Highlight = '#FF0D63',

  HighlightSecondary = '#FAFF00',
  HighlightSecondaryLight = '#FEFECE',

  Error = '#FFBBBB'
}
